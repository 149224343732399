<template>
  <a
    :href="demoMode ? undefined : navItem.url"
    target="_blank"
    class="box-border appearance-none select-none text-sm/3 text-black-50 hover:text-blue active:text-blue visited:text-black-50 cursor-pointer"
    :style="{ fontWeight }"
  >
    {{ navItem.name }}
  </a>
</template>

<script lang="ts">
export default { name: 'NavItem' };
</script>

<script setup lang="ts">
import { TLink } from '@/helpers/types/shared.types';

defineProps<{
  navItem: TLink;
  fontWeight: string;
  demoMode?: boolean;
}>();
</script>

<style scoped lang="scss"></style>
