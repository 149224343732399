<template>
  <span v-if="errorMessages?.length" class="flex flex-col gap-1">
    <span v-for="message in errorMessages" :key="message" class="text-xs text-red-500">
      {{ message }}
    </span>
  </span>
</template>

<script lang="ts">
export default { name: 'UIErrorMessages' };
</script>

<script setup lang="ts">
defineProps<{
  errorMessages?: string[];
}>();
</script>

<style scoped lang="scss"></style>
