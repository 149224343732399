<template>
  <div class="flex items-center gap-5">
    <img
      class="h-[16px]"
      src="../../../assets/images/shared/mmco-logo/mmco-logo.svg"
      alt="Логотип MMCO"
    />

    <div class="text-sm text-gray">{{ FOOTER_COPYRIGHT }}</div>
  </div>
</template>

<script lang="ts">
export default { name: 'FooterCopyright' };
</script>

<script setup lang="ts">
import { FOOTER_COPYRIGHT } from '@/helpers/constants/layout.constants';
</script>

<style scoped lang="scss"></style>
