import { useRouter } from 'vue-router/composables';
import { ref } from 'vue';
import store from '@/store';

export function useHeaderSearch(inputId: string, demoMode: boolean, doSearchCb?: () => void) {
  const router = useRouter();

  const searchValue = ref('');
  const clearSearch = () => {
    searchValue.value = '';
  };

  const isSearchOpened = ref(false);
  const openSearch = () => {
    isSearchOpened.value = true;
    setTimeout(() => {
      const inputElem = document.getElementById(inputId);
      inputElem?.focus();
    }, 0);
  };
  const closeSearch = () => {
    isSearchOpened.value = false;
  };

  const clearAndCloseSearch = () => {
    clearSearch();
    closeSearch();
  };

  const doSearch = async () => {
    if (demoMode) return;

    const trimmedValue = searchValue.value.trim();
    if (trimmedValue) {
      await router.push({ path: '/search', query: { search: `${trimmedValue}` } });
      await store.dispatch('client/AGetSearchInfo', trimmedValue);
      clearAndCloseSearch();
      doSearchCb?.();
    }
  };

  return {
    searchValue,
    clearSearch,
    isSearchOpened,
    openSearch,
    closeSearch,
    clearAndCloseSearch,
    doSearch,
  };
}
