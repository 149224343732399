
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { cloneDeep, find, remove } from 'lodash';
import AppCheckBox from '@/ui/components/switchers/app-check-box/AppCheckBox.vue';
import AppIcon from '@/ui/components/icons/app-icon/AppIcon.vue';
import { TUIMultiselectOpenDirection } from '@/helpers/types/ui.types';
import UIErrorMessages from '@/components/UI/shared/UIErrorMessages.vue';

const __default__ = defineComponent({
  name: 'UIMultiselect',
  components: { UIErrorMessages, AppIcon, AppCheckBox },
  props: {
    selectedList: {
      type: Array,
      required: true,
    },
    optionsList: {
      type: Array,
      required: true,
    },
    keyField: {
      type: String,
      required: false,
      default: 'key',
    },
    labelField: {
      type: String,
      required: false,
      default: 'label',
    },
    placeholderText: {
      type: String,
      required: false,
      default: 'Выберите один или несколько вариантов',
    },
    emptyOptionsText: {
      type: String,
      required: false,
      default: 'Пусто',
    },
    openDirection: {
      type: String as PropType<TUIMultiselectOpenDirection>,
      required: false,
      default: 'bottom',
    },
    errorMessages: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },
  },
  emits: ['update'],
  setup(props, { emit }) {
    const isOpenOptions = ref(false);

    // Используется для того, чтобы отправлять изменения только при необходимости
    const innerSelectedList = ref(cloneDeep<any[]>(props.selectedList));

    const selectedRemoveId = 'selected-list-item-remove';

    const clickSelectedList = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (target.id !== selectedRemoveId) {
        isOpenOptions.value = !isOpenOptions.value;
      }
    };

    const clickSelectedRemove = (keyFieldValue: any) => {
      // Мутировать список напрямую не получится, так как теряется реактивность
      const listToEmit = cloneDeep(innerSelectedList.value);
      remove(listToEmit, { [props.keyField]: keyFieldValue });
      innerSelectedList.value = listToEmit;
      // Так как удалить элемент можно и при закрытом списке, отправляем эмит
      if (!isOpenOptions.value) emit('update', listToEmit);
    };

    const findOptionInSelected = (keyFieldValue: any) => {
      return find(innerSelectedList.value, { [props.keyField]: keyFieldValue });
    };

    const clearSelected = () => {
      innerSelectedList.value = [];
    };

    const clickOption = (item: any) => {
      const selectedOption = findOptionInSelected(item[props.keyField]);
      // Мутировать список напрямую не получится, так как теряется реактивность
      const listToEmit = cloneDeep(innerSelectedList.value);
      if (selectedOption) {
        remove(listToEmit, { [props.keyField]: item[props.keyField] });
      } else {
        listToEmit.push(item);
      }
      innerSelectedList.value = listToEmit;
    };

    const chevronTransform = computed(() => {
      return isOpenOptions.value ? '180deg' : '0deg';
    });

    watch(
      () => isOpenOptions.value,
      (newValue) => {
        // Отправляем данные наружу когда список закрывается (бизнес требование)
        if (!newValue) emit('update', innerSelectedList.value);
      }
    );
    // Мы вынуждены следить за списком, так как изменения могут поступить извне
    watch(
      () => props.selectedList,
      (newValue) => {
        innerSelectedList.value = newValue;
      }
    );

    return {
      isOpenOptions,
      innerSelectedList,
      selectedRemoveId,
      clickSelectedList,
      clickSelectedRemove,
      findOptionInSelected,
      clearSelected,
      clickOption,
      chevronTransform,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "0877b3ef": (_vm.chevronTransform)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__