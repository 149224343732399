<template>
  <UIPopover
    :disabled="!disabled || !checkIsSlotFilled($slots.disabledTip)"
    trigger="hover"
    :placement="disabledTipPosition"
    class="w-full"
  >
    <template #control>
      <button
        v-bind="$attrs"
        :type="submit ? 'submit' : 'button'"
        :disabled="disabled"
        class="box-border appearance-none font-base w-full flex justify-center items-center font-medium"
        :class="{
          [typeClass]: true,

          'stroke-current fill-none': iconColorApproach === 'stroke',
          'fill-current': iconColorApproach === 'fill',

          'cursor-not-allowed opacity-30': disabled,
          'hover:cursor-pointer': !disabled,

          'h-[64px] border-2 gap-2.5 text-lg lg:text-lg/5 px-4 rounded-md': size === 'extra-large',
          'h-[56px] border-2 gap-2.5 text-lg lg:text-lg/5 px-4 rounded-md': size === 'large',
          'h-[50px] border-2 gap-2.5 text-base lg:text-base/5 px-3 rounded-md': size === 'medium',
          'h-[46px] border-2 gap-2.5 text-base lg:text-base/5 px-3 rounded': size === 'middle',
          'h-[36px] border-2 gap-2.5 text-sm lg:text-sm/3 px-2 rounded': size === 'small',
          'h-[32px] border-[1.5px] gap-2 text-xs lg:text-xs/1 px-1.5 rounded':
            size === 'extra-small',

          'w-[64px] rounded-full': icon && size === 'extra-large',
          'w-[56px] rounded-full': icon && size === 'large',
          'w-[50px] rounded-full': icon && size === 'medium',
          'w-[46px] rounded-full': icon && size === 'middle',
          'w-[36px] rounded-full': icon && size === 'small',
          'w-[32px] rounded-full': icon && size === 'extra-small',
        }"
        @click="$emit('click')"
      >
        <UIIcon v-if="!icon && iconStart" :name="iconStart" :size="iconSize" />

        <span v-if="!icon && checkIsSlotFilled($slots.default)"><slot /></span>

        <UIIcon v-if="!icon && iconEnd" :name="iconEnd" :size="iconSize" />

        <UIIcon v-if="icon" :name="icon" :size="iconSize" :rotate="iconRotate" />
      </button>
    </template>

    <slot name="disabledTip"></slot>
  </UIPopover>
</template>

<script lang="ts">
export default { name: 'UIButton', inheritAttrs: false };
</script>

<script setup lang="ts">
import { computed } from 'vue';
import { checkIsSlotFilled } from '@/helpers/methods/slots.method';
import UIIcon from '@/components/UI/UIIcon/UIIcon.vue';
import { UISize, TUIButtonType, TUIIconName, TUIPopoverPlacement } from '@/helpers/types/ui.types';
import UIPopover from '@/components/UI/UIPopover.vue';

const props = withDefaults(
  defineProps<{
    type: TUIButtonType;
    size: UISize;
    ghost?: boolean;
    light?: boolean;
    // Используется, если нужно отобразить только иконку, без текста
    // Стилизуется по обычным правилам type, ширина фиксируется, скругление 50 процентов
    icon?: TUIIconName;
    iconRotate?: string;

    iconStart?: TUIIconName;
    iconEnd?: TUIIconName;
    iconColorApproach?: 'stroke' | 'fill';
    disabled?: boolean;
    disabledTipPosition?: TUIPopoverPlacement;
    submit?: boolean;
  }>(),
  {
    iconColorApproach: 'stroke',
  }
);

defineEmits<{
  (e: 'click'): void;
}>();

const typeClass = computed(() => {
  if (props.ghost) {
    return `${props.type}Ghost`;
  } else if (props.light) {
    return `${props.type}Light`;
  } else {
    return props.type;
  }
});

const iconSize = computed(() => {
  switch (props.size) {
    case 'small':
    case 'extra-small':
      return 20;
    default:
      return 30;
  }
});
</script>

<style lang="scss" scoped>
.primary {
  @apply text-[var(--button-text-color)] bg-client border-none;

  &:hover:not(:disabled) {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%),
      theme('colors.client');
  }
  &:active:not(:disabled) {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.08) 100%),
      theme('colors.client');
  }
}
.primaryGhost {
  @apply text-black-50 bg-neutral-100;

  &:hover:not(:disabled) {
    @apply bg-neutral-200;
  }
  &:active:not(:disabled) {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.08) 100%),
      theme('colors.neutral.200');
  }
}
.primaryLight {
  @apply text-black-50 bg-white;

  &:hover:not(:disabled) {
    @apply bg-neutral-100;
  }
  &:active:not(:disabled) {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.08) 100%),
      theme('colors.neutral.100');
  }
}

.secondary {
  @apply text-client bg-white border-client border-solid;

  &:hover:not(:disabled) {
    background: linear-gradient(
        0deg,
        theme('colors.client / 15%') 0%,
        theme('colors.client / 15%') 100%
      ),
      theme('colors.white');
  }
  &:active:not(:disabled) {
    background: linear-gradient(
        0deg,
        theme('colors.client / 15%') 0%,
        theme('colors.client / 15%') 100%
      ),
      linear-gradient(0deg, theme('colors.client / 15%') 0%, theme('colors.client / 15%') 100%),
      theme('colors.white');
  }
}
.secondaryGhost {
  @apply text-gray bg-white border-neutral-200 border-solid;

  &:hover:not(:disabled) {
    @apply text-black-50 bg-neutral-100;
  }
  &:active:not(:disabled) {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.08) 100%),
      theme('colors.neutral.100');
  }
}

.link {
  @apply text-blue font-normal bg-white;
  @apply rounded-none #{!important};

  &:hover:not(:disabled) {
    @apply border-[0] border-b border-solid;
  }
  &:active:not(:disabled) {
    @apply text-client border-[0] border-b border-solid;
  }
}
</style>
