<template>
  <span
    v-if="checkIsSlotFilled($slots['label'])"
    class="flex items-center gap-1"
    :class="{
      'text-gray': isDisabled,

      'text-base lg:text-base/5': size === 'extra-large',
      'text-sm lg:text-sm/2': size !== 'extra-large',
    }"
  >
    <slot name="label" />

    <UIPopover v-if="checkIsSlotFilled($slots['label-info'])" trigger="hover" placement="top">
      <template #control>
        <UIIcon name="info" :size="20" class="stroke-gray hover:stroke-client fill-none" />
      </template>

      <div class="text-start"><slot name="label-info" /></div>
    </UIPopover>
  </span>
</template>

<script lang="ts">
export default { name: 'UILabel' };
</script>

<script setup lang="ts">
import { checkIsSlotFilled } from '@/helpers/methods/slots.method';
import UIPopover from '@/components/UI/UIPopover.vue';
import UIIcon from '@/components/UI/UIIcon/UIIcon.vue';
import { UISize } from '@/helpers/types/ui.types';

defineProps<{
  size: UISize;
  isDisabled?: boolean;
}>();
</script>

<style scoped lang="scss"></style>
