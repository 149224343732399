<template>
  <div class="box-border fixed left-[0] right-[0] z-[100] bg-black-50 text-white">
    <div
      class="container flex justify-between items-center"
      :style="{ height: ADMIN_LAYOUT_SIZES.headerHeight }"
    >
      <div class="flex items-center gap-10">
        <div class="flex items-center gap-1.5 select-none pb-[5px]">
          <UIIcon name="settings" :size="24" class="stroke-white fill-none" />
          <div class="text-sm/3 font-medium">Режим администратора</div>
        </div>

        <nav class="flex items-center gap-5 pb-[5px]">
          <!--          TODO Отображать только для CLIENT_ADMIN-->
          <router-link
            :to="{ name: 'AdminSpacePage' }"
            active-class="active-class"
            class="appearance-none text-sm/3 font-medium cursor-pointer hover:text-blue active:text-blue visited:text-white"
          >
            Пространство
          </router-link>
          <router-link
            :to="{ name: 'AdminEvents' }"
            active-class="active-class"
            class="appearance-none text-sm/3 font-medium cursor-pointer hover:text-blue active:text-blue visited:text-white"
          >
            События
          </router-link>
        </nav>
      </div>

      <DesktopUser type="admin" color-variant="BLACK" :is-header-hidden="false" />
    </div>
  </div>
</template>

<script lang="ts">
export default { name: 'AdminHeader' };
</script>

<script setup lang="ts">
import UIIcon from '@/components/UI/UIIcon/UIIcon.vue';
import DesktopUser from '@/layouts/shared/DesktopUser.vue';
import { ADMIN_LAYOUT_SIZES } from '@/helpers/constants/layout.constants';
</script>

<style scoped lang="scss">
.active-class {
  @apply text-blue underline #{!important};
}
</style>
