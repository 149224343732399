var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"box-border w-full"},[_c('div',{staticClass:"container h-[62px] flex justify-between items-center gap-2"},[_c('div',{staticClass:"flex-1 flex justify-between items-center gap-5"},[_c('div',{staticClass:"flex gap-12"},[_c(_setup.HeaderClientLogo,{attrs:{"max-height":"46px","logo-url":_vm.logoUrl}}),(!_setup.isSearchOpened && _vm.variant === 'NAV_INLINE' && _vm.navItems.length)?_c('nav',{staticClass:"flex items-center gap-5"},_vm._l((_vm.navItems),function(navItem){return _c(_setup.NavItem,{key:navItem.name,attrs:{"nav-item":navItem,"font-weight":"500","demo-mode":_vm.demoMode}})}),1):_vm._e()],1),_c('div',{staticClass:"transition-[all] duration-300 bg-neutral-100 rounded-[50px]",class:{
          'w-full': _setup.isSearchOpened,
          'w-[46px]': !_setup.isSearchOpened,
        }},[(!_setup.isSearchOpened)?_c('div',{staticClass:"box-border w-[46px] h-[46px] flex justify-center items-center rounded-full hover:bg-neutral-200 active:bg-neutral-200 cursor-pointer",on:{"click":function($event){return _setup.openSearch()}}},[_c(_setup.UIIcon,{staticClass:"stroke-black-50 fill-none",attrs:{"name":"search","size":24}})],1):_vm._e(),(_setup.isSearchOpened)?_c('form',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(() => _setup.closeSearch()),expression:"() => closeSearch()"}],staticClass:"w-full",on:{"submit":function($event){$event.preventDefault();return _setup.doSearch()}}},[_c(_setup.UIInput,{attrs:{"size":"small","placeholder":"Поиск","round":"","input-id":_setup.inputId},scopedSlots:_vm._u([{key:"leftPlace",fn:function(){return [_c(_setup.UIIcon,{staticClass:"stroke-black-50 fill-none mr-2.5",attrs:{"name":"search","size":18}})]},proxy:true},(_setup.searchValue)?{key:"rightPlace",fn:function(){return [_c('div',{staticClass:"w-[18px] h-[18px] flex justify-center items-center stroke-white fill-none bg-gray rounded-full hover:bg-blue active:bg-blue ml-2.5 cursor-pointer"},[_c(_setup.UIIcon,{attrs:{"name":"x","size":14},on:{"click":function($event){return _setup.clearSearch()}}})],1)]},proxy:true}:null],null,true),model:{value:(_setup.searchValue),callback:function ($$v) {_setup.searchValue=$$v},expression:"searchValue"}})],1):_vm._e()])]),_c(_setup.DesktopUser,{attrs:{"type":"default","color-variant":"WHITE","is-header-hidden":_vm.isHeaderHidden,"demo-mode":_vm.demoMode}})],1),((_vm.variant === 'NAV_BOTTOM_LEFT' || _vm.variant === 'NAV_BOTTOM_FULL') && _vm.navItems?.length)?_c('nav',[_c('div',{staticClass:"w-full h-[1px] bg-neutral-200"}),_c('div',{staticClass:"container w-full flex py-2",class:{
        'justify-start gap-7.5': _vm.variant === 'NAV_BOTTOM_LEFT',
        'justify-between': _vm.variant === 'NAV_BOTTOM_FULL',
      }},_vm._l((_vm.navItems),function(navItem){return _c(_setup.NavItem,{key:navItem.name,attrs:{"nav-item":navItem,"font-weight":"500","demo-mode":_vm.demoMode}})}),1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }