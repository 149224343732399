<template>
  <div class="box-border">
    <!--          считаем ширину для того, чтобы анимация работала нормально-->
    <div class="h-[62px] flex items-center bg-white px-5" v-click-outside="closeSearch">
      <div class="w-full flex justify-between items-center gap-1.5">
        <div v-if="!isSearchOpened" class="flex-1 flex justify-between items-center">
          <HeaderClientLogo max-height="46px" @click.native="closeMenu" />
        </div>

        <div v-if="isSearchOpened" class="flex-1 flex items-center gap-2.5">
          <form class="w-full" @submit.prevent="doSearch">
            <UIInput
              v-model="searchValue"
              size="small"
              placeholder="Поиск"
              round
              :input-id="inputId"
            >
              <template #leftPlace>
                <UIIcon name="search" :size="18" class="stroke-gray fill-none mr-2.5" />
              </template>
              <template v-if="searchValue" #rightPlace>
                <UIIcon
                  name="x"
                  :size="24"
                  class="stroke-gray fill-none active:stroke-blue ml-2.5"
                  @click="clearSearch"
                />
              </template>
            </UIInput>
          </form>
        </div>

        <div v-if="!isSearchOpened" class="flex items-center gap-1.5">
          <div
            class="w-[46px] h-[46px] flex justify-center items-center rounded-full transition-all duration-300"
            :class="{
              'active:bg-neutral-100': !isMenuOpened,
              'bg-neutral-100 active:bg-neutral-200': isMenuOpened,
            }"
            @click="openSearch"
          >
            <UIIcon name="search" :size="24" class="stroke-black-50 fill-none" />
          </div>
        </div>

        <div
          class="w-[46px] h-[46px] flex justify-center items-center rounded-full"
          :class="{
            'active:bg-neutral-100': !isMenuOpened,
            'bg-neutral-100 active:bg-neutral-200': isMenuOpened,
          }"
          @click="toggleMenu"
        >
          <UIIcon
            :name="isMenuOpened ? 'x' : 'menu'"
            :size="24"
            class="stroke-black-50 fill-none"
          />
        </div>
      </div>
    </div>

    <transition name="fade" mode="out-in">
      <div
        v-if="isMenuOpened"
        class="h-[calc(100vh-62px)] bg-neutral-100 flex flex-col gap-[25px] px-5 pt-[25px] border-t-2 border-neutral-200 border-solid"
      >
        <nav v-if="navItems.length" class="w-full appearance-none flex flex-col">
          <MobileNavItem v-for="item in navItems" :key="item.name" :item="item" />
        </nav>

        <div v-if="navItems.length" class="border-[0] border-t-2 border-neutral-200 border-solid" />

        <HeaderMobileUser @close="toggleMenu" />
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
export default { name: 'HeaderMobile' };
</script>

<script setup lang="ts">
import { ref } from 'vue';
import UIIcon from '@/components/UI/UIIcon/UIIcon.vue';
import HeaderMobileUser from '@/layouts/Header/Mobile/User/HeaderMobileUser.vue';
import MobileNavItem from '@/layouts/Header/Mobile/MobileNavItem.vue';
import { THeaderColorVariant } from '@/helpers/types/layouts.types';
import UIInput from '@/components/UI/UIInput/UIInput.vue';
import HeaderClientLogo from '@/layouts/Header/shared/HeaderClientLogo.vue';
import { useHeaderSearch } from '@/helpers/composables/header-search.composable';
import { TLink } from '@/helpers/types/shared.types';

defineProps<{
  colorVariant: THeaderColorVariant;
  navItems: TLink[];
}>();

const isMenuOpened = ref(false);
const toggleMenu = () => {
  isMenuOpened.value = !isMenuOpened.value;
  document.body.classList.toggle('lock');
};
const closeMenu = () => {
  isMenuOpened.value = false;
  document.body.classList.remove('lock');
};

const inputId = 'header-mobile-input';
const { searchValue, isSearchOpened, openSearch, closeSearch, clearSearch, doSearch } =
  useHeaderSearch(inputId, false, () => {
    closeMenu();
  });
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
