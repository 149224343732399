var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.UIPopover,{staticClass:"w-full",attrs:{"disabled":!_vm.disabled || !_setup.checkIsSlotFilled(_vm.$slots.disabledTip),"trigger":"hover","placement":_vm.disabledTipPosition},scopedSlots:_vm._u([{key:"control",fn:function(){return [_c('button',_vm._b({staticClass:"box-border appearance-none font-base w-full flex justify-center items-center font-medium",class:{
        [_setup.typeClass]: true,

        'stroke-current fill-none': _vm.iconColorApproach === 'stroke',
        'fill-current': _vm.iconColorApproach === 'fill',

        'cursor-not-allowed opacity-30': _vm.disabled,
        'hover:cursor-pointer': !_vm.disabled,

        'h-[64px] border-2 gap-2.5 text-lg lg:text-lg/5 px-4 rounded-md': _vm.size === 'extra-large',
        'h-[56px] border-2 gap-2.5 text-lg lg:text-lg/5 px-4 rounded-md': _vm.size === 'large',
        'h-[50px] border-2 gap-2.5 text-base lg:text-base/5 px-3 rounded-md': _vm.size === 'medium',
        'h-[46px] border-2 gap-2.5 text-base lg:text-base/5 px-3 rounded': _vm.size === 'middle',
        'h-[36px] border-2 gap-2.5 text-sm lg:text-sm/3 px-2 rounded': _vm.size === 'small',
        'h-[32px] border-[1.5px] gap-2 text-xs lg:text-xs/1 px-1.5 rounded':
          _vm.size === 'extra-small',

        'w-[64px] rounded-full': _vm.icon && _vm.size === 'extra-large',
        'w-[56px] rounded-full': _vm.icon && _vm.size === 'large',
        'w-[50px] rounded-full': _vm.icon && _vm.size === 'medium',
        'w-[46px] rounded-full': _vm.icon && _vm.size === 'middle',
        'w-[36px] rounded-full': _vm.icon && _vm.size === 'small',
        'w-[32px] rounded-full': _vm.icon && _vm.size === 'extra-small',
      },attrs:{"type":_vm.submit ? 'submit' : 'button',"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click')}}},'button',_vm.$attrs,false),[(!_vm.icon && _vm.iconStart)?_c(_setup.UIIcon,{attrs:{"name":_vm.iconStart,"size":_setup.iconSize}}):_vm._e(),(!_vm.icon && _setup.checkIsSlotFilled(_vm.$slots.default))?_c('span',[_vm._t("default")],2):_vm._e(),(!_vm.icon && _vm.iconEnd)?_c(_setup.UIIcon,{attrs:{"name":_vm.iconEnd,"size":_setup.iconSize}}):_vm._e(),(_vm.icon)?_c(_setup.UIIcon,{attrs:{"name":_vm.icon,"size":_setup.iconSize,"rotate":_vm.iconRotate}}):_vm._e()],1)]},proxy:true}],null,true)},[_vm._t("disabledTip")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }