var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(() => (_setup.isShownList = false)),expression:"() => (isShownList = false)"}],staticClass:"h-fit relative"},[_c('div',{staticClass:"h-fit"},[_vm._t("control",null,{"onClick":_setup.toggleList,"isShownList":_setup.isShownList})],2),_c('transition',{attrs:{"name":_vm.positionY === 'top' ? 'slide-down' : 'slide-up',"mode":"out-in"}},[(_setup.isShownList)?_c('div',{ref:"optionsRef",staticClass:"absolute z-[1001] bg-white rounded-md p-2.5 shadow",style:(`width: ${_vm.optionsWidth};
    ${_vm.positionY}: calc(-${_setup.optionsRef?.offsetHeight + 6}px);
    ${_vm.positionX}: 0`)},_vm._l((_vm.options),function(option){return _c('div',{key:option.key,staticClass:"rounded-sm select-none cursor-pointer hover:bg-neutral-100 text-sm lg:text-sm/3",class:{
          'whitespace-nowrap': _vm.optionsWidth === 'auto',
          'bg-neutral-100': option.selected,
          'px-2.5 py-2': !option.prevent,
        },on:{"click":function($event){return _setup.clickOption(option)}}},[_vm._t(option.key,function(){return [_c('div',{staticClass:"flex items-center gap-4"},[(option.iconStart)?_c(_setup.UIIcon,{staticClass:"stroke-current fill-none",attrs:{"name":option.iconStart}}):_vm._e(),_c('div',[_vm._v(" "+_vm._s(option.label)+" ")])],1)]},{"option":option})],2)}),0):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }