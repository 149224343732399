<template>
  <div class="h-fit relative" v-click-outside="() => (isShownList = false)">
    <div class="h-fit">
      <slot name="control" :onClick="toggleList" :isShownList="isShownList" />
    </div>

    <transition :name="positionY === 'top' ? 'slide-down' : 'slide-up'" mode="out-in">
      <div
        v-if="isShownList"
        class="absolute z-[1001] bg-white rounded-md p-2.5 shadow"
        :style="`width: ${optionsWidth};
      ${positionY}: calc(-${optionsRef?.offsetHeight + 6}px);
      ${positionX}: 0`"
        ref="optionsRef"
      >
        <div
          v-for="option in options"
          :key="option.key"
          class="rounded-sm select-none cursor-pointer hover:bg-neutral-100 text-sm lg:text-sm/3"
          :class="{
            'whitespace-nowrap': optionsWidth === 'auto',
            'bg-neutral-100': option.selected,
            'px-2.5 py-2': !option.prevent,
          }"
          @click="clickOption(option)"
        >
          <slot :name="option.key" v-bind:option="option">
            <div class="flex items-center gap-4">
              <UIIcon
                v-if="option.iconStart"
                :name="option.iconStart"
                class="stroke-current fill-none"
              />
              <div>
                {{ option.label }}
              </div>
            </div>
          </slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
export default { name: 'UIOptions' };
</script>

<script setup lang="ts">
import { ref } from 'vue';
import { TUIOptionsItem } from '@/helpers/types/ui.types';
import UIIcon from '@/components/UI/UIIcon/UIIcon.vue';

withDefaults(
  defineProps<{
    options: TUIOptionsItem[];
    positionX?: 'left' | 'right';
    positionY?: 'top' | 'bottom';
    optionsWidth?: string;
  }>(),
  {
    positionX: 'right',
    positionY: 'bottom',
    optionsWidth: 'auto',
  }
);
const emits = defineEmits<{
  (event: 'select', value: string): void;
  (event: 'click'): void;
}>();

const isShownList = ref(false);
const closeList = () => {
  isShownList.value = false;
};
const toggleList = () => {
  isShownList.value = !isShownList.value;
};

const optionsRef = ref<HTMLElement | null>(null);
const clickOption = (option: TUIOptionsItem) => {
  if (option.prevent) return;
  emits('select', option.key);
  closeList();
};

defineExpose({
  isShownList,
  closeList,
});
</script>

<style scoped lang="scss">
@import '~@/assets/styles/shared/slide-transition';
</style>
