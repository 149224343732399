import { disabledFeature } from '@/helpers/methods/disabled-feature.method';
import { EX_$Modal } from '@/classes/content';
import router from '@/router';
import { useAuthStore } from '@/store/pinia/auth/auth.store';
import { TLayoutUserItem } from '@/helpers/types/layouts.types';

export class LayoutsService {
  public static async clickUserItem(item: TLayoutUserItem): Promise<void> {
    const authStore = useAuthStore();

    switch (item) {
      case 'ProfilePage':
        disabledFeature('profile')
          ? await EX_$Modal.toggleModal('appModalAccessDenied')
          : await router.push({ name: 'ProfilePage' });
        break;

      case 'PersonalAccount':
        disabledFeature('personal_account')
          ? await EX_$Modal.toggleModal('appModalAccessDenied')
          : await router.push({ name: 'PersonalAccount' });
        break;

      case 'login':
        await EX_$Modal.toggleModal('appModalAuth');
        break;

      case 'logout':
        await authStore.logout();
        break;

      case 'AdminEvents':
        localStorage.setItem('currentRouteForLeave', '/admin-events?category=all');
        await router.push({ name: 'AdminEvents' });
        break;

      default:
        await router.push({ name: item });
    }
  }
}
