<template>
  <div class="ui-multiselect" v-click-outside="() => (isOpenOptions = false)">
    <div
      class="selected"
      :class="{
        'border-red-500 border-solid border-2': errorMessages?.length,
      }"
      @click="clickSelectedList"
    >
      <ul v-if="innerSelectedList.length" class="selected-list">
        <li v-for="item in innerSelectedList" :key="item[keyField]" class="selected-list-item">
          <div class="selected-list-item-content">
            <slot name="selected" v-bind:selected="item">
              {{ item[labelField] }}
            </slot>
          </div>
          <div
            class="selected-list-item-remove"
            :id="selectedRemoveId"
            @click.stop="clickSelectedRemove(item[keyField])"
          >
            <AppIcon name="close" size="10" />
          </div>
        </li>
      </ul>
      <div v-if="!innerSelectedList.length" class="selected-empty">
        <div class="selected-empty-text">{{ placeholderText }}</div>
        <AppIcon name="chevron-down" class="chevron" :size="24" />
      </div>
    </div>

    <UIErrorMessages :error-messages="errorMessages" class="mt-1" />

    <Transition>
      <div v-if="isOpenOptions" class="options" :class="openDirection">
        <ul v-if="optionsList.length" class="options-list">
          <!--          Ожидаю обратной связи по этой кнопке-->
          <!--          <li class="options-list-item options-list-item-clear" @click="clearSelected()">-->
          <!--            {{ innerSelectedList.length ? 'Очистить' : 'Не выбрано' }}-->
          <!--          </li>-->
          <li
            v-if="innerSelectedList.length"
            class="options-list-item options-list-item-clear"
            @click="clearSelected()"
          >
            Очистить
          </li>
          <li
            v-for="item in optionsList"
            :key="item[keyField]"
            class="options-list-item"
            @click.prevent="clickOption(item)"
          >
            <div class="options-list-item-checkbox">
              <AppCheckBox
                text=""
                style="height: 24px; width: 24px; padding: 0"
                :is-checked="Boolean(findOptionInSelected(item[keyField]))"
              />
            </div>
            <div class="options-list-item-content">
              <slot name="option" v-bind:option="item">
                {{ item[labelField] }}
              </slot>
            </div>
          </li>
        </ul>
        <div v-if="!optionsList.length" class="options-empty">{{ emptyOptionsText }}</div>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { cloneDeep, find, remove } from 'lodash';
import AppCheckBox from '@/ui/components/switchers/app-check-box/AppCheckBox.vue';
import AppIcon from '@/ui/components/icons/app-icon/AppIcon.vue';
import { TUIMultiselectOpenDirection } from '@/helpers/types/ui.types';
import UIErrorMessages from '@/components/UI/shared/UIErrorMessages.vue';

export default defineComponent({
  name: 'UIMultiselect',
  components: { UIErrorMessages, AppIcon, AppCheckBox },
  props: {
    selectedList: {
      type: Array,
      required: true,
    },
    optionsList: {
      type: Array,
      required: true,
    },
    keyField: {
      type: String,
      required: false,
      default: 'key',
    },
    labelField: {
      type: String,
      required: false,
      default: 'label',
    },
    placeholderText: {
      type: String,
      required: false,
      default: 'Выберите один или несколько вариантов',
    },
    emptyOptionsText: {
      type: String,
      required: false,
      default: 'Пусто',
    },
    openDirection: {
      type: String as PropType<TUIMultiselectOpenDirection>,
      required: false,
      default: 'bottom',
    },
    errorMessages: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },
  },
  emits: ['update'],
  setup(props, { emit }) {
    const isOpenOptions = ref(false);

    // Используется для того, чтобы отправлять изменения только при необходимости
    const innerSelectedList = ref(cloneDeep<any[]>(props.selectedList));

    const selectedRemoveId = 'selected-list-item-remove';

    const clickSelectedList = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (target.id !== selectedRemoveId) {
        isOpenOptions.value = !isOpenOptions.value;
      }
    };

    const clickSelectedRemove = (keyFieldValue: any) => {
      // Мутировать список напрямую не получится, так как теряется реактивность
      const listToEmit = cloneDeep(innerSelectedList.value);
      remove(listToEmit, { [props.keyField]: keyFieldValue });
      innerSelectedList.value = listToEmit;
      // Так как удалить элемент можно и при закрытом списке, отправляем эмит
      if (!isOpenOptions.value) emit('update', listToEmit);
    };

    const findOptionInSelected = (keyFieldValue: any) => {
      return find(innerSelectedList.value, { [props.keyField]: keyFieldValue });
    };

    const clearSelected = () => {
      innerSelectedList.value = [];
    };

    const clickOption = (item: any) => {
      const selectedOption = findOptionInSelected(item[props.keyField]);
      // Мутировать список напрямую не получится, так как теряется реактивность
      const listToEmit = cloneDeep(innerSelectedList.value);
      if (selectedOption) {
        remove(listToEmit, { [props.keyField]: item[props.keyField] });
      } else {
        listToEmit.push(item);
      }
      innerSelectedList.value = listToEmit;
    };

    const chevronTransform = computed(() => {
      return isOpenOptions.value ? '180deg' : '0deg';
    });

    watch(
      () => isOpenOptions.value,
      (newValue) => {
        // Отправляем данные наружу когда список закрывается (бизнес требование)
        if (!newValue) emit('update', innerSelectedList.value);
      }
    );
    // Мы вынуждены следить за списком, так как изменения могут поступить извне
    watch(
      () => props.selectedList,
      (newValue) => {
        innerSelectedList.value = newValue;
      }
    );

    return {
      isOpenOptions,
      innerSelectedList,
      selectedRemoveId,
      clickSelectedList,
      clickSelectedRemove,
      findOptionInSelected,
      clearSelected,
      clickOption,
      chevronTransform,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '~@/ui/styles/colors/index.scss';
@import '~@/ui/styles/font/style.scss';
@import '~@/ui/styles/theme/_color_theme.scss';

.ui-multiselect {
  user-select: none;
  position: relative;
}

.selected {
  background: $SMOKY-WHITE;
  border-radius: 12px;
  cursor: pointer;
  &-list {
    padding: 8px 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    @media (min-width: 1101px) {
      padding: 13px 16px;
    }
    &-item {
      background: #ffffff;
      border-radius: 5px;
      padding: 6px;
      display: flex;
      align-items: center;
      gap: 4px;
      @media (min-width: 1101px) {
        padding: 4px 6px;
      }
      &-content {
        color: $ALMOST-BLACK;
        font-family: $font-family-Suisse-Intl-Regular, sans-serif;
        font-size: 13px;
        line-height: 17px;
        @media (min-width: 1101px) {
          font-size: 14px;
          line-height: 20px;
        }
      }
      &-remove {
        padding: 1px 5px;
        cursor: pointer;
      }
    }
  }
  &-empty {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    @media (min-width: 1101px) {
      padding: 15.5px 16px;
    }
    &-text {
      color: $DARK-TELEGRAY;
      font-family: $font-family-Suisse-Intl-Regular, sans-serif;
      font-size: 12px;
      line-height: 20px;
      @media (min-width: 1101px) {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}
.options {
  position: absolute;
  //top: 54px;
  left: 0;
  right: 0;
  z-index: 99;
  background-color: white;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  //@media (min-width: 1101px) {
  //  top: 61px;
  //}
  &-list {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 162px;
    overflow-y: auto;
    @media (min-width: 1101px) {
      max-height: 202px;
    }
    &-item {
      padding: 8px;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 15px;
      &-clear {
        color: $DARK-TELEGRAY;
        font-family: $font-family-Suisse-Intl-Regular, sans-serif;
        font-size: 16px;
        line-height: 22px;
      }
      &-checkbox {
        height: 20px;
        width: 20px;
      }
      &-content {
        color: $ALMOST-BLACK;
        font-family: $font-family-Suisse-Intl-Regular, sans-serif;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}
.bottom {
  top: calc(100% + 8px);
  @media (min-width: 1101px) {
    top: calc(100% + 15px);
  }
}
.top {
  bottom: calc(100% + 8px);
  @media (min-width: 1101px) {
    bottom: calc(100% + 15px);
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.chevron {
  transform: rotate(v-bind(chevronTransform));
  transition-duration: 0.3s;
  transition-property: transform;
}
</style>
