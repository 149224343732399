import { render, staticRenderFns } from "./NavItem.vue?vue&type=template&id=4e7c1233&scoped=true"
import script from "./NavItem.vue?vue&type=script&setup=true&lang=ts"
export * from "./NavItem.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e7c1233",
  null
  
)

export default component.exports