<template>
  <div class="box-border w-full">
    <div class="container h-[62px] flex justify-between items-center gap-2">
      <div class="flex-1 flex justify-between items-center gap-5">
        <div class="flex gap-12">
          <HeaderClientLogo max-height="46px" :logo-url="logoUrl" />

          <nav
            v-if="!isSearchOpened && variant === 'NAV_INLINE' && navItems.length"
            class="flex items-center gap-5"
          >
            <NavItem
              v-for="navItem in navItems"
              :key="navItem.name"
              :nav-item="navItem"
              font-weight="500"
              :demo-mode="demoMode"
            />
          </nav>
        </div>

        <div
          class="transition-[all] duration-300 bg-neutral-100 rounded-[50px]"
          :class="{
            'w-full': isSearchOpened,
            'w-[46px]': !isSearchOpened,
          }"
        >
          <div
            v-if="!isSearchOpened"
            class="box-border w-[46px] h-[46px] flex justify-center items-center rounded-full hover:bg-neutral-200 active:bg-neutral-200 cursor-pointer"
            @click="openSearch()"
          >
            <UIIcon name="search" :size="24" class="stroke-black-50 fill-none" />
          </div>

          <form
            v-if="isSearchOpened"
            class="w-full"
            v-click-outside="() => closeSearch()"
            @submit.prevent="doSearch()"
          >
            <UIInput
              v-model="searchValue"
              size="small"
              placeholder="Поиск"
              round
              :input-id="inputId"
            >
              <template #leftPlace>
                <UIIcon name="search" :size="18" class="stroke-black-50 fill-none mr-2.5" />
              </template>
              <template v-if="searchValue" #rightPlace>
                <div
                  class="w-[18px] h-[18px] flex justify-center items-center stroke-white fill-none bg-gray rounded-full hover:bg-blue active:bg-blue ml-2.5 cursor-pointer"
                >
                  <UIIcon name="x" :size="14" @click="clearSearch()" />
                </div>
              </template>
            </UIInput>
          </form>
        </div>
      </div>

      <DesktopUser
        type="default"
        color-variant="WHITE"
        :is-header-hidden="isHeaderHidden"
        :demo-mode="demoMode"
      />
    </div>

    <nav
      v-if="(variant === 'NAV_BOTTOM_LEFT' || variant === 'NAV_BOTTOM_FULL') && navItems?.length"
    >
      <div class="w-full h-[1px] bg-neutral-200" />

      <div
        class="container w-full flex py-2"
        :class="{
          'justify-start gap-7.5': variant === 'NAV_BOTTOM_LEFT',
          'justify-between': variant === 'NAV_BOTTOM_FULL',
        }"
      >
        <NavItem
          v-for="navItem in navItems"
          :key="navItem.name"
          :nav-item="navItem"
          font-weight="500"
          :demo-mode="demoMode"
        />
      </div>
    </nav>
  </div>
</template>

<script lang="ts">
export default { name: 'HeaderDesktop' };
</script>

<script setup lang="ts">
import NavItem from '@/layouts/shared/NavItem.vue';
import DesktopUser from '@/layouts/shared/DesktopUser.vue';
import HeaderClientLogo from '@/layouts/Header/shared/HeaderClientLogo.vue';
import UIIcon from '@/components/UI/UIIcon/UIIcon.vue';
import UIInput from '@/components/UI/UIInput/UIInput.vue';
import { THeaderVariant, THeaderColorVariant } from '@/helpers/types/layouts.types';
import { useHeaderSearch } from '@/helpers/composables/header-search.composable';
import { TLink } from '@/helpers/types/shared.types';

const props = defineProps<{
  variant: THeaderVariant;
  colorVariant: THeaderColorVariant;
  navItems: TLink[];
  isHeaderHidden: boolean;
  logoUrl?: string;
  demoMode?: boolean;
}>();

const inputId = 'header-desktop-input';
const { searchValue, clearSearch, isSearchOpened, openSearch, closeSearch, doSearch } =
  useHeaderSearch(inputId, Boolean(props.demoMode));
</script>

<style scoped lang="scss"></style>
