<template>
  <div class="box-border">
    <div v-if="!authStore.isLoggedIn" class="w-[115px]">
      <UIButton
        type="primary"
        size="middle"
        @click="clickItem('login')"
        data-cy="open-login-button"
      >
        Войти
      </UIButton>
    </div>

    <div v-if="authStore.isLoggedIn" class="text-black-50">
      <UIOptions ref="uiOptionsRef" :options="options" @select="clickItem">
        <template #control="{ onClick, isShownList }">
          <div class="flex items-center gap-1 cursor-pointer select-none" @click="onClick">
            <HeaderUserAvatar />

            <UIIcon
              name="chevron-up"
              :rotate="isShownList ? '0deg' : '-180deg'"
              transform-style="transition-duration: 0.3s; transition-property: transform"
              class="fill-none hover:stroke-client active:stroke-client"
              :class="{
                'stroke-black-50': colorVariant === 'WHITE',
                'stroke-white': colorVariant === 'BLACK',
              }"
            />
          </div>
        </template>
      </UIOptions>
    </div>
  </div>
</template>

<script lang="ts">
export default { name: 'DesktopUser' };
</script>

<script setup lang="ts">
import { useAuthStore } from '@/store/pinia/auth/auth.store';
import UIButton from '@/components/UI/UIButton/UIButton.vue';
import { LayoutsService } from '@/services/layouts/layouts.service';
import UIOptions from '@/components/UI/UIOptions/UIOptions.vue';
import { TUIOptionsItem } from '@/helpers/types/ui.types';
import { computed, ref, watch } from 'vue';
import { THeaderColorVariant, TLayoutUserItem } from '@/helpers/types/layouts.types';
import _ from 'lodash';
import UIIcon from '@/components/UI/UIIcon/UIIcon.vue';
import { isHaveRole } from '@/utils/custom';
import { useWindowSizeStore } from '@/store/pinia/window-size/window-size.store';
import HeaderUserAvatar from '@/layouts/Header/shared/HeaderUserAvatar.vue';

const props = defineProps<{
  type: 'default' | 'admin';
  colorVariant: THeaderColorVariant;
  isHeaderHidden: boolean;
  demoMode?: boolean;
}>();

const windowSizeStore = useWindowSizeStore();
const authStore = useAuthStore();

const options = computed<TUIOptionsItem[]>(() => {
  const getLinkBetweenLayouts = () => {
    switch (props.type) {
      case 'default': {
        const isLinkShown =
          !props.demoMode &&
          authStore.isLoggedIn &&
          (isHaveRole('CLIENT_ADMIN') ||
            isHaveRole('CLIENT_MANAGER') ||
            isHaveRole('EVENT_ADMIN') ||
            isHaveRole('MMCO_ADMIN')) &&
          windowSizeStore.isWindow.desktop;
        return isLinkShown
          ? {
              key: 'AdminEvents',
              label: 'Администрируемые события',
            }
          : undefined;
      }

      case 'admin': {
        return {
          key: 'SpacePage',
          label: 'Главная',
        };
      }
    }
  };

  return _.compact([
    getLinkBetweenLayouts(),
    {
      key: 'ProfilePage',
      label: 'Профиль',
    },
    {
      key: 'PersonalAccount',
      label: 'Личный кабинет',
    },
    {
      key: 'logout',
      label: 'Выйти',
    },
  ]);
});

const clickItem = async (item: TLayoutUserItem): Promise<void> => {
  if (props.demoMode) return;

  await LayoutsService.clickUserItem(item);
};

const uiOptionsRef = ref<any>(null);
watch(
  () => props.isHeaderHidden,
  (newValue) => {
    if (newValue) {
      uiOptionsRef.value?.closeList();
    }
  }
);
</script>

<style scoped lang="scss"></style>
